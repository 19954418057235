.tiles-item-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  padding: 15px 0;
}

.text__justify {
  text-align: justify;
}

.features-tiles-item-header,
.mb-8 {
  text-align: center;
}

.mb-16 {
  margin-top: 10px;
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.features-tiles-item-image,
.features-tiles-item-image .mb-16 {
  display: inline-flex;
  border-radius: 50%;
  background-color: #5658dd;
  padding: 10px;
}

@media (min-width: 641px) {
  .features-tiles .tiles-wrap:last-of-type {
    margin-bottom: -5px;
  }

  .features-tiles .tiles-wrap {
    /* margin-right: -32px; */
    /* margin-left: -32px; */
    margin-top: 15px;
  }

  .tiles-wrap {
    /* display: grid; */
    margin-top: 20px;
    gap: 10rem;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
  }

  .center-content {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    text-align: center;
  }

  .features-tiles .tiles-item {
    flex-basis: 330px;
    max-width: 330px;
  }

  .tiles-item,
  .tiles-wrap.push-left:after {
    flex-basis: 330px;
    max-width: 330px;
    box-sizing: content-box;
  }
}
