
  label{
    width:150px;
  }
  
  input{
    width:200px;
    margin: 2px;
  } 
  
  .leaflet-container {
    width: 18rem;
    height: 30vh;
  }
  
  .map {
    width: 10%;
    height: 10%;
  } 
  
  @media screen and (max-width: '480px') {
    .leaflet-container {
        width: 20vw;
        height: 25vh;
      }
      
      .map__area {
        width: 10%;
        height: 10%;
      } 
  }
  
 