.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: #2642af;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  color: black;
  list-style-type: none;
  margin: 0 2rem;
}

.navigation-menu li a {
  color: black;
  text-decoration: none;
  display: block;
  width: 100%;
}

.navigation-menu li:last-child {
  margin-right: 10rem;
}

.hamburger {
  /* // removes default border on button element */
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

/* THE MOBILE VERSION STYLING */

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
    /* // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
    position: absolute;
    top: 60px;
    left: 0;
    /* // stacks the li tags vertically  */
    flex-direction: column;
    /* // makes menu span full height and width */
    width: 100%;
    /* height: calc(100vh - 77px); */
    background-color: white;
    border-top: 1px solid black;
  }
  .navigation-menu li,
  .navigation-menu li:last-child {
    /* // centers link text and strips off margin */
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    /* // increases the surface area of the anchor tag to span the full width of the menu */
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu.expanded ul {
    display: block;
    z-index: 10;
  }
}
