.Section1 {
  margin-bottom: 1em;
}
.section-headings {
  /* width: 68%; */
  margin: 2em auto;
}
.Section1-heading {
  font-weight: 100;
  line-height: 1.5em;
}
.Section1-content {
  font-size: 1.5em;
  font-weight: 300;
  margin: 0.5em;
}
.fa-chart-bar,
.diamond {
  display: block;
  font-size: 3em;
  color: red;
  padding: 0px 0.7em;
  border: 2px solid #0002;
  border-radius: 50%;
  margin: 0.5em auto;
}
.fa-gem {
  color: #9262ff;
}
.service-Content-section {
  margin-left: 1em;
}
.serviceSection-heading {
  font-size: 1.5em;
  font-weight: 400;
  margin: 1em auto;
}
.serviceSection-content {
  width: 100%;
  font-size: 1em;
  opacity: 0.6;
  padding: 1em;

  margin: auto;
}


@media (min-width: 576px) {
  .row11 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    /* width: 800px; */
  }
}

@media (max-width: 800px) {
  .row11 {
    display: grid;
    gap: 2rem;
  
  }

  .Section1 {
    width: 100%;
  }
  .section-headings {
    width: 100%;
  }
  .Section1-heading {
    width: 100%;
    font-weight: 400;
    font-size: 1em;
  }
  .Section1-content {
    width: 100%;
    font-weight: 400;
    font-size: 0.7em;
  }
  .fa-chart-bar,
  .diamond {
    font-size: 2em;
  }
  .serviceSection-heading {
    font-size: 1em;
  }
  .serviceSection-content {
    font-size: 0.7em;
  }
}
