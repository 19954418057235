table,
td,
td,
th {
  font-size: 1.2rem;
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;

  @media only screen and (max-width: 57.81em) {
    font-size: 0.8rem !important;
  }

  @media only screen and (max-width: 28.125em) {
    font-size: 0.6rem !important;
  }

  @media only screen and (max-width: 21.875em) {
    font-size: 0.5rem !important;
  }

  @media only screen and (max-width: 16.875em) {
    font-size: 0.35rem !important;
  }
}

.section-center-table {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;

  @media only screen and (max-width: 28.125em) {
    width: 95vw !important;
    /* margin-left: 2rem; */
  }
}
table {
  margin: 0 auto;
}

td a,
td a:link,
td a:visited {
  display: inline-block;
  text-decoration: none;
  /* width: 75%; */
  color: #fff;
  background-color: #7392c8;
  padding: 0.6rem 1.2rem;
  border-radius: 0.5rem;

  @media only screen and (max-width: 57.81em) {
    padding: 0.3rem 0.6rem !important;
  }
  /* color: rgb(55, 99, 176) ; */
}

td a:hover,
td a:active {
  background-color: #5f82c0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  gap: 5rem;

  @media only screen and (max-width: 21.875em) {
    gap: 2rem !important;
  }
}
