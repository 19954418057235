:root {
  /* dark shades of primary color text*/
  /* --clr-primary-1: hsl(237, 39%, 50%);
  --clr-primary-2: hsl(231, 33%, 55%);
  --clr-primary-3: hsl(231, 84%, 78%);
  --clr-primary-4: hsl(234, 28%, 45%); */

  /* --clr-primary-1: hsl(22, 28%, 21%);
  --clr-primary-2: hsl(22, 28%, 29%);
  --clr-primary-3: hsl(22, 28%, 37%);
  --clr-primary-4: hsl(22, 28%, 45%); */

  /* primary/main color */
  --clr-primary-5: hsl(227, 37%, 60%);
  /* --clr-primary-5: hsl(22, 31%, 52%); */
  /* lighter shades of primary color */
  --clr-primary-6: hsl(22, 31%, 60%);
  /* --clr-primary-7: hsl(223, 79%, 64%); */
  --clr-primary-7: rgb(55, 99, 176);
  --clr-primary-8: hsl(20, 31%, 74%);
  --clr-primary-9: hsl(22, 31%, 81%);
  --clr-primary-10: hsl(206, 100%, 92%);
  /* --clr-primary-10: hsl(22, 31%, 88%); */
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  /* --fixed-width: 620px; */
}

/* section */
.section {
  padding: 2rem 0 1rem;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
